<script>
  import { onMount } from 'svelte'
  import Icon from 'svelte-awesome'
  import { refresh } from 'svelte-awesome/icons'
  import { Datatable, SearchInput } from 'svelte-simple-datatables'
  import CompanyModal from '../components/OwnerModal.svelte'
  import { token } from '../../stores'
  import { getAllOwners } from '../../services/owner'

  let showModal = false
  let modalCompany = {}
  let modify = false

  let selectedRowId
  let filterEmptyOwners = true

  let companyList = []
  let loading = true
  let rows
  const settings = {
    columnFilter: true,
    labels: {
      search: 'Leita...',
    },
    rowsPerPage: 35,
    blocks: {
      searchInput: false,
      paginationButtons: true,
      paginationRowCount: true,
    },
  }

  onMount(async () => {
    //Gets all owners / companies
    const response = await getAllOwners($token)
    if (response) {
      loading = false
      companyList = response
    } else {
      console.log('ERROR getting all compnies / owners ')
    }
  })

  //if "Create owner" is clicked
  const openModalCreate = () => {
    showModal = true
    modify = false
  }

  const openModalModify = (company) => {
    showModal = true
    modalCompany = company
    modify = true
  }

  function handleModalClosed() {
    showModal = false
  }

  //If a new owner is either "created" or "updated" we re-render the owners
  async function handleNewCompany() {
    const responseNewCompany = await getAllOwners($token)
    if (responseNewCompany) {
      companyList = responseNewCompany
      modalCompany = {}
    } else {
      console.log('ERROR getting all compnies / owners ')
    }
  }

  function setSelected(rowId) {
    if (selectedRowId == rowId) {
      selectedRowId = ''
    } else {
      selectedRowId = rowId
    }
  }

  function filterByEmpty(owner) {
    if (owner && owner.name) {
      return true
    }
    return false
  }
</script>

<CompanyModal showModal="{showModal}" on:modalClosed="{handleModalClosed}" on:companyCreated="{handleNewCompany}" company="{modalCompany}" modify="{modify}" companyList="{companyList}" />

<div>
  {#if loading}
    <div class="section">
      <Icon spin data="{refresh}" scale="3" />
    </div>
  {:else}
    <div>
      <div class="level">
        <div class="level-item has-text-centered">
          <h1 class="title is-3 has-text-centered" style="display: inline;">Útgerðaraðilar</h1>
        </div>
      </div>
      <div class="level">
        <div class="level-left">
          <SearchInput class="input" id="companyTable" />
          <label class="checkbox">
            <input type="checkbox" style="margin-left: 10px;" bind:checked="{filterEmptyOwners}" />
            Sýna útgerðaraðila sem hafa ekki nöfn
          </label>
        </div>
        <button
          class="button is-success"
          style="margin-right: 16px;"
          on:click="{() => {
            modify = false
            openModalCreate()
          }}"
        >
          Stofna útgerðaraðila
        </button>
      </div>
      <div style="min-height:75vh">
        <Datatable settings="{settings}" data="{filterEmptyOwners ? companyList : companyList.filter(filterByEmpty)}" bind:dataRows="{rows}" id="companyTable">
          <thead>
            <tr>
              <th data-key="name">Nafn</th>
              <th data-key="kennitala">Kennitala</th>
              <th data-key="address">Heimilisfang</th>
              <th data-key="postCode">Póstnúmer</th>
            </tr>
          </thead>
          <tbody>
            {#if rows}
              {#each $rows as row}
                <tr
                  style="text-align: center;"
                  class:selected="{row.id == selectedRowId}"
                  on:click="{() => {
                    setSelected(row.id)
                  }}"
                >
                  <td>{row.name}</td>
                  <td>{row.kennitala}</td>
                  <td>{row.address}</td>
                  <td>{row.postCode} {row.place}</td>
                </tr>
                {#if row.id == selectedRowId}
                  <tr
                    class="modify-owner"
                    on:click="{() => {
                      openModalModify(row)
                    }}"
                  >
                    <td style="text-align: center;" colspan="4"> Uppfæra útgerðaraðila </td>
                  </tr>
                {/if}
              {/each}
            {/if}
          </tbody>
        </Datatable>
      </div>
    </div>
  {/if}
</div>

<style>
  tr:hover {
    background: whitesmoke;
  }

  tr.selected {
    font-weight: bolder;
    background: #f5f5f5;
  }
  tr.selected:hover {
    background: #e6e6e6;
  }

  tr.modify-owner {
    font-weight: bolder;
    background: #edf3f8;
  }
  tr.modify-owner:hover {
    background: #dae7f1;
    cursor: pointer;
  }
</style>
