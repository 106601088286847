<script>
  import { onMount } from 'svelte'
  import AutoComplete from 'simple-svelte-autocomplete'
  import { createEventDispatcher } from 'svelte'
  import { FormField } from '../../helpers'
  import { user, token } from '../../stores'

  //Service calls
  import { getAllVessels, updateOrCreateVessel, getVesselTypes, getVesselMaterials, getVesselClasses, getVesselLicense, deleteVesselById } from '../../services/vessel'
  import { getAllOwners } from '../../services/owner'
  import { getAllPorts } from '../../services/port'
  import { getAllDocks } from '../../services/dock'
  // import Docking from '../admin/Docking.svelte';
  import { navigateTo } from 'svelte-router-spa'

  export let showModal
  export let vessel = {}
  export let modify = false

  let vesselList = []
  let companyList = []
  let portList = []
  let dockList = []
  let typeList = []
  let classList = []
  let materialList = []
  let licenseList = []
  let appUrl = 'https://skraning.slipp.is/roles'

  let idField = new FormField('', '', false, true)
  let imoField = new FormField()
  let mmsiField = new FormField()

  onMount(async () => {
    //Start of by getting a auth $token if the user has one

    //Gets all vessels from database
    const response = await getAllVessels($token)
    if (response) {
      vesselList = response
    } else {
      console.log('ERROR getting all vessels ')
    }
    //Get all owners
    const responseCompany = await getAllOwners($token)
    if (responseCompany) {
      companyList = responseCompany
    } else {
      console.log('ERROR getting all compnies / owners ')
    }

    //Get all ports
    const responsePorts = await getAllPorts($token)
    if (responsePorts) {
      portList = responsePorts
    } else {
      console.log('ERROR getting all ports ')
    }

    //Get all docks
    const responseDocks = await getAllDocks($token)
    if (responseDocks) {
      dockList = responseDocks
    } else {
      console.log('ERROR getting all docks ')
    }

    //Get all vessel-types
    const responseVesselType = await getVesselTypes($token)
    if (responseVesselType) {
      typeList = responseVesselType
    } else {
      console.log('ERROR getting all vessel types ')
    }

    //Get all vessel Materials
    const responseMaterials = await getVesselMaterials($token)
    if (responseMaterials) {
      materialList = responseMaterials
    } else {
      console.log('ERROR getting all vessel materials ')
    }

    //Get all vessel classes
    const responseClasses = await getVesselClasses($token)
    if (responseClasses) {
      classList = responseClasses
    } else {
      console.log('ERROR getting all vessel classes ')
    }



    //Get all vessel License
    const responseLicense = await getVesselLicense($token)
    if (responseLicense) {
      licenseList = responseLicense
    } else {
      console.log('ERROR getting all vessel licenses ')
    }
  })

  const dispatch = createEventDispatcher()

  function nullifyObjects() {
    //if objects are empty we need to nullifie them since the picker returns value "undifined "
    if (vessel.dock === undefined) {
      vessel.dock = { id: null, name: null }
    }
    if (vessel.type === undefined) {
      vessel.type = { id: null, name: null }
    }
    if (vessel.hullMaterial === undefined) {
      vessel.hullMaterial = { id: null, name: null }
    }
    if (vessel.class === undefined) {
      vessel.class = { id: null, name: null }
    }
    if (vessel.owner === undefined) {
      vessel.owner = { id: null, name: null, kennitala: null, address: null, postno: null }
    }
    if (vessel.operator === undefined) {
      vessel.operator = { id: null, name: null, kennitala: null, address: null, postCode: null, place: null }
    }
    if (vessel.port === undefined) {
      vessel.port = { id: null, name: null }
    }
    if (vessel.length === undefined || vessel.length === '') {
      vessel.length = null
    }
    if (vessel.breadth === undefined || vessel.breadth === '') {
      vessel.breadth = null
    }
    if (vessel.depth === undefined || vessel.depth === '') {
      vessel.depth = null
    }
    if (vessel.maxLength === undefined || vessel.maxLength === '') {
      vessel.maxLength = null
    }
    if (vessel.grossWeight === undefined || vessel.grossWeight === '') {
      vessel.grossWeight = null
    }
    if (vessel.grossVolume === undefined || vessel.grossVolume === '') {
      vessel.grossVolume = null
    }
  }

  async function sendVessel() {
    console.log(vessel)
    if (!isFormValid()) {
      return
    }
    nullifyObjects()
    let success = false
    //Create or update a vessel depending of modify
    //Success returns true if update or create was succesfull
    success = await updateOrCreateVessel($token, vessel, modify)

    closeModal()
    if (success) {
      dispatch('vesselCreated', { vessel: vessel })
    }
  }

  function closeModal() {
    dispatch('modalClosed')
  }
  async function deleteVessel() {
    if(confirm("Viltu EYÐA þessu Þilfarskipi? / Do you want to DELETE this VESSEL")){
    if ($user[appUrl][0] == 'SuperUser') {
      const response = await deleteVesselById($token, vessel.id)
      if (response) {
        navigateTo(`/vessel`)
      } else {
        console.log('ERROR deleting vessel by id ', idField)
      }
    }
    }else{
      console.log("User canceled the delete action")
    }
  }

  function isFormValid() {
    let idIsOk = false
    let imoIsOk = true
    let mmsiIsOk = true

    if (!modify) {
      idField.class = 'is-danger'
      idField.showHelpText = true
      if (vessel && vessel.id) {
        if (vesselList.find((v) => v.id == vessel.id)) {
          idField.helpText = `Vessel with id ${vessel.id} already registered`
        } else if ((vessel.id <= 0 || vessel.id >= 10000) && (vessel.id.length != 7 || vessel.id > 9999999) && (vessel.id.length != 9 || vessel.id > 999999999)) {
          idField.helpText = 'Skipnr þarf að vera á bili 1-9999, Eða IMO-nr á bili 0000000-9999999 (7 tölur), eða MMSI-nr á bili 000000000-999999999 (9 tölur)'
        } else {
          idIsOk = true
        }
      } else if (idField.required) {
        idField.helpText = `Vessel id is required`
      } else {
        idIsOk = true
      }
    } else {
      idIsOk = true
    }
    //For the imo field
    if (vessel && vessel.imo) {
      imoField.class = 'is-danger'
      imoField.showHelpText = true
      // Naive IMO checker without check digit calculation
      if (vessel.imo.search(/\D/) != -1 || vessel.imo > 9999999 || vessel.imo.length != 7) {
        imoField.helpText = 'IMO number must be 7 digits between 0000000 and 9999999'
        imoIsOk = false
      } else if (vesselList.find((v) => vessel.imo == v.imo && vessel.id != v.id)) {
        imoField.helpText = `IMO number ${vessel.imo} already registered`
        imoIsOk = false
      }
    } else if (imoField.required) {
      imoField.helpText = `IMO is required`
      imoIsOk = false
    }
    //For the mmsi field
    if (vessel && vessel.mmsi) {
      mmsiField.class = 'is-danger'
      mmsiField.showHelpText = true
      // Naive IMO checker without check digit calculation
      if (vessel.mmsi.search(/\D/) != -1 || vessel.mmsi > 999999999 || vessel.mmsi.length != 9) {
        mmsiField.helpText = 'MMSI number must be 9 digits between 000000000 and 999999999'
        mmsiIsOk = false
      } else if (vesselList.find((v) => vessel.mmsi == v.mmsi && vessel.id != v.id)) {
        mmsiField.helpText = `MMSI number ${vessel.mmsi} already registered`
        mmsiIsOk = false
      }
    } else if (mmsiField.required) {
      mmsiField.helpText = `MMSI is required`
      mmsiIsOk = false
    }
    idField = idIsOk ? new FormField('', '', false, true) : idField
    imoField = imoIsOk ? new FormField() : imoField
    mmsiField = mmsiIsOk ? new FormField() : mmsiField

    return idIsOk && imoIsOk && mmsiIsOk
  }
</script>

<div class="{showModal ? 'modal is-active' : 'modal'}">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">{modify ? 'Uppfæra' : 'Stofna'} Skip </p>


            <button class="delete"  on:click="{() => {
            closeModal()
          }}" aria-label="close"></button>
    </header>
    <section class="section modal-card-body large">
      <div class="field">
        <label for="vesselName" class="label">Nafn skips</label>
        <div class="control">
          <input id="vesselName" class="input" type="text" placeholder="Name" bind:value="{vessel.name}" />
        </div>
      </div>
      <div class="field">
        <label for="vesselId" class="label extra-text"><p style="color:red; font-size: 20px; position: relative; top: -4px ">*</p>Sknr <p class="has-text-info">(Skrá IMO-nr eða MMSI-nr ef skpnr er ekki til staðar)</p></label>
        <div class="control">
          <input id="vesselId" class="input is-primary required {idField.class}" type="text" placeholder="ID" bind:value="{vessel.id}" disabled="{modify}" />
          <p class="help {idField.class}">{idField.helpText}</p>
        </div>
      </div>
      <div class="field">
        <label for="vesselImo" class="label extra-text"><p>IMO-nr</p> <p class="has-text-info">(7 tölur)</p></label>
        <div class="control">
          <input id="vesselImo" class="input {imoField.class}" type="text" placeholder="IMO" bind:value="{vessel.imo}" />
          <p class="help {imoField.class}">{imoField.showHelpText ? imoField.helpText : ''}</p>
        </div>
      </div>
      <div class="field">
        <label for="vesselMmsi" class="label extra-text"><p>MMSI-nr </p><p class="has-text-info">(9 tölur)</p></label>
        <div class="control">
          <input id="vesselMmsi" class="input {mmsiField.class}" type="text" placeholder="MMSI" bind:value="{vessel.mmsi}" />
          <p class="help {mmsiField.class}">{mmsiField.showHelpText ? mmsiField.helpText : ''}</p>
        </div>
      </div>
      <div class="field">
        <label for="vesselType" class="label">Skipa tegund</label>
        <div class="control">
          <AutoComplete items="{typeList}" labelFieldName="name" valueFieldName="id" bind:selectedItem="{vessel.type}" placeholder="Type" showClear="{true}" />
        </div>
      </div>
            <div class="field">
        <label for="vesselOwner" class="label">Útgerðaraðili</label>
        <div class="control">
          <AutoComplete items="{companyList}" labelFieldName="name" valueFieldName="id" bind:selectedItem="{vessel.owner}" placeholder="Owner" showClear="{true}" />
        </div>
      </div>
      <div class="field">
        <label for="vesselOperator" class="label">Eigandi</label>
        <div class="control">
          <AutoComplete items="{companyList}" labelFieldName="name" valueFieldName="id" bind:selectedItem="{vessel.operator}" placeholder="Operator" showClear="{true}" />
        </div>
      </div>
      <div class="field">
        <label for="vesselClass" class="label">Klassi</label>
        <div class="control">
          <AutoComplete items="{classList}" labelFieldName="name" valueFieldName="id" bind:selectedItem="{vessel.class}" placeholder="Hull Material" showClear="{true}" />
        </div>
      </div>

      <div class="field">
        <label for="vesselPort" class="label">Höfn</label>
        <div class="control">
          <AutoComplete items="{portList}" labelFieldName="name" valueFieldName="id" bind:selectedItem="{vessel.port}" placeholder="Port" showClear="{true}" />
        </div>
      </div>
      <div>
        <hr>
        <p class="title is-4">Ýtarlegri upplýsingar</p>
        <hr>
      </div>
      {#if modify}
      <div class="field">
        <label for="vesselLicense" class="label">Veiðileyfi í gildi</label>
        <div class="control">
          <AutoComplete multiple="true" items="{licenseList}" labelFieldName="name" valueFieldName="id" bind:selectedItem="{vessel.license}" placeholder="...." showClear="{true}" />
        </div>
      </div>
      {/if}
      <div class="field">
        <label for="vesselBuildYear" class="label">Smíðaár</label>
        <div class="control">
          <input id="vesselBuildYear" class="input" type="text" placeholder="Build Year" bind:value="{vessel.buildYear}" />
        </div>
      </div>
      <div class="field">
        <label for="vesselHullMaterial" class="label">Bol efni</label>
        <div class="control">
          <AutoComplete items="{materialList}" labelFieldName="name" valueFieldName="id" bind:selectedItem="{vessel.hullMaterial}" placeholder="Class" showClear="{true}" />
        </div>
      </div>
      <div class="field">
        <label for="vesselLength" class="label">Lengd (í metrum)</label>
        <div class="control">
          <input id="vesselLength" class="input" type="text" placeholder="Length" bind:value="{vessel.length}" />
        </div>
      </div>
      <div class="field">
        <label for="vesselBreadth" class="label">Breidd (í metrum)</label>
        <div class="control">
          <input id="vesselBreadth" class="input" type="text" placeholder="Breadth" bind:value="{vessel.breadth}" />
        </div>
      </div>
      <div class="field">
        <label for="vesselDepth" class="label">Dýpt (í metrum)</label>
        <div class="control">
          <input id="vesselDepth" class="input" type="text" placeholder="Depth" bind:value="{vessel.depth}" />
        </div>
      </div>
      <div class="field">
        <label for="vesselMaxLength" class="label">Mesta lengd (í metrum)</label>
        <div class="control">
          <input id="vesselMaxLength" class="input" type="text" placeholder="Length" bind:value="{vessel.maxLength}" />
        </div>
      </div>
      <div class="field">
        <label for="vesselMaxLength" class="label">Brúttótonn (br.tonn)</label>
        <div class="control">
          <input id="vesselWeight" class="input" type="text" placeholder="Weight" bind:value="{vessel.grossWeight}" />
        </div>
      </div>
      <div class="field">
        <label for="vesselMaxLength" class="label">Brúttó rúmmál (b.rúml)</label>
        <div class="control">
          <input id="vesselVolume" class="input" type="text" placeholder="Volume" bind:value="{vessel.grossVolume}" />
        </div>
      </div>
    </section>

    <footer class="modal-card-foot" >
     <button
        class="button is-success"
        on:click="{() => {
          sendVessel()
        }}">{modify ? 'Update' : 'Create'}</button
      >
      <button
        class="button"
        on:click="{() => {
          closeModal()
        }}">Cancel</button
      >

            {#if modify}
        <!-- Only show the delete button of the user has acces to it, the access is changesd in auth0, read more about it on slippskraning notion -->
        {#if $user[appUrl] ? $user[appUrl][0] == 'SuperUser' : false}
          <button
            class="button is-danger is-outlined"
            on:click="{() => {
              deleteVessel()
            }}">Delete</button
          >
        {/if}
      {/if}
    </footer>
  </div>
</div>




<style>
  .extra-text {
  display: flex; 
  gap: 0.4rem; 
  }

</style>