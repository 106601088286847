import axios from 'axios'

//gets all ports
export async function getAllPorts(token) {
  try {
    const response = await axios.get(`${process.env.API_ROOT}/api/port`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  } catch (e) {
    console.error(e)
  }
}
