<script>
  import { onMount } from 'svelte'
  import { navigateTo } from 'svelte-router-spa'
  import { token, filterDockSide, filterFloatingDock, filterMainDock, filterSmallDock } from '../../stores'
  import Icon from 'svelte-awesome'
  import { refresh } from 'svelte-awesome/icons'
  import { Datatable, SearchInput } from 'svelte-simple-datatables'
  import { getAllDocksProcess } from '../../services/dock'

  import { greyOut, lightBlue, lightGreen, lightRed, lightYellow } from '../../constants/colors'

  let allDockingInfo
  let loading = true
  let rows
  const settings = {
    columnFilter: true,
    rowsPerPage: 20,
    labels: {
      search: 'Leita...',
    },
    blocks: {
      searchInput: false,
      paginationButtons: true,
      paginationRowCount: true,
    },
  }

  onMount(async () => {
    const response = await getAllDocksProcess($token)
    if (response) {
      allDockingInfo = response
    } else {
      console.log('ERROR getting dockins ', response)
    }
    loading = false
  })

  //When vessels in the table are clicked
  const openVesselDock = (vesselId, dockId) => {
    const route = `vessel/${vesselId}/docking/${dockId}`
    navigateTo(route)
  }

  function filterByDock(docking) {
    if (filterByValid(docking) && $filterSmallDock && docking.dock === 'Litla dráttarbraut') {
      return true
    }
    if (filterByValid(docking) && $filterMainDock && docking.dock === 'Aðaldráttarbraut') {
      return true
    }
    if (filterByValid(docking) && $filterFloatingDock && docking.dock === 'Flotkví') {
      return true
    }
    if (filterByValid(docking) && $filterDockSide && docking.dock === 'Bryggjukantur') {
      return true
    }
    return false
  }

  function filterByValid(docking) {
    if (docking && docking.id) {
      return true
    }
    return false
  }
</script>

<div>
  <div class="level-item has-text-centered">
    <h1 class="title is-3 has-text-centered" style="display: inline; ">Verkefnalisti</h1>
  </div>
  {#if loading}
    <div class="section">
      <Icon spin data="{refresh}" scale="3" />
    </div>
  {:else}
    <div style="padding-bottom: 0rem;">
      <div class="level">
        <div class="level-left">
          <SearchInput class="input" id="{'vesselTable'}" />

                  <div style="padding-left: .5rem;">
          <label class="checkbox">
            <input type="checkbox" style="margin-left: 10px; transform: scale(1.2)" bind:checked="{$filterFloatingDock}" />
            Flotkví
          </label>
          <label class="checkbox">
            <input type="checkbox" style="margin-left: 10px; transform: scale(1.2)" bind:checked="{$filterSmallDock}" />
            Litla dráttarbraut
          </label>
          <label class="checkbox">
            <input type="checkbox" style="margin-left: 10px; transform: scale(1.2)" bind:checked="{$filterMainDock}" />
            Aðaldráttarbraut
          </label>
          <label class="checkbox">
            <input type="checkbox" style="margin-left: 10px; transform: scale(1.2)" bind:checked="{$filterDockSide}" />
            Bryggjukantur
          </label>
        </div>
        </div>
      </div>

      <div style="min-height:80vh" class="table">
        <Datatable settings="{settings}" data="{$filterFloatingDock || $filterSmallDock || $filterMainDock || $filterDockSide ? allDockingInfo.filter(filterByDock) : allDockingInfo.filter(filterByValid)}" bind:dataRows="{rows}" id="{'vesselTable'}">
          <thead>
            <tr>
              <th data-key="dock">Upptökumannvirki</th>
              <th data-key="vesselName">Nafn</th>
              <th data-key="vesselId">Númer</th>
              <th data-key="location"> Staður slipps eða þjónustu</th>
              <th data-key="startDate">Upphaf verks</th>
              <th data-key="slippDate">Slipptaka</th>
              <th data-key="conclusionDate">Niðursetning</th>
              <th data-key="endDate">Lok verks</th>
              <th data-key="supervision">Umsjón</th>
              <th data-key="contacts">Tengiliðir útgerðar</th>
            </tr>
          </thead>
          <tbody>
            {#if rows}
              {#each $rows as row}
                <tr class="clickable" style="text-align: center; " on:click="{() => openVesselDock(row.vesselId, row.id)}">
                  <td
                    style="border: rgba(0,0,0,0.2) 1px solid; {row.dock === 'Litla dráttarbraut'
                      ? `background-color:${lightGreen};`
                      : row.dock === 'Aðaldráttarbraut'
                      ? `background-color:${lightBlue};`
                      : row.dock === 'Flotkví'
                      ? `background-color:${lightYellow};`
                      : row.dock === 'Bryggjukantur'
                      ? `background-color:${lightRed};`
                      : `background-color:${greyOut};`}">{row.dock ? row.dock : `---`}</td
                  >
                  <td> {row.vesselName ? row.vesselName : '---'}</td>
                  <td> {row.vesselId ? row.vesselId : '---'}</td>
                  <td> {row.location ? row.location : '---'}</td>
                  <td>{row.startDate ? row.startDate : '---'}</td>
                  <td>{row.slippDate ? row.slippDate : '---'}</td>
                  <td>{row.conclusionDate ? row.conclusionDate : '---'}</td>
                  <td>{row.endDate ? row.endDate : '---'}</td>
                  <td style="white-space:normal; inline-size: 130px; word-break: break-word;">{row.supervision ? row.supervision : '---'}</td>
                  <td nowrap style="white-space:normal; inline-size: 180px; word-break: break-word;">{row.contacts ? row.contacts : '---'}</td>
                </tr>
              {/each}
            {/if}
          </tbody>
        </Datatable>
      </div>
    </div>
  {/if}
</div>

<style>
  tr:hover {
    transform: scaleY(1.05);
    background: whitesmoke;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  }
  td {
    vertical-align: middle;
  }
</style>
