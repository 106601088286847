import axios from 'axios'
import { toaster } from '../helpers'
//get all owners from database
export async function getAllOwners(token) {
  try {
    const response = await axios.get(`${process.env.API_ROOT}/api/company`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  } catch (e) {
    console.error(e)
  }
}

//Create a new owner or update owner by id
export async function updateOrCreateOwners(token, company, modify, dispatch) {
  try {
    delete company.place
    if (modify) {
      await axios.put(`${process.env.API_ROOT}/api/company/${company.id}`, company, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
    } else {
      await axios.post(`${process.env.API_ROOT}/api/company`, company, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
    }
    dispatch('companyCreated')
    let message = `Success. Company ${modify ? (company.name ? company.name : company.id) + ' updated' : ' created'}`
    toaster(message)
  } catch (e) {
    let message = e.response.data.message
    toaster(message, true)
  }
}

export async function deleteOwnerById(token, routeOwnerId) {
  try {
    await axios.delete(`${process.env.API_ROOT}/api/company/${routeOwnerId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    let message = `Owner deleted`
    toaster(message)
    return true
  } catch (e) {
    console.error(e.message)
    toaster(e.message, true)
  }
}
