<script>
  import auth from '../../authService'
  import { auth0Client } from '../../stores.js'

  let active
  const toggleBurger = () => {
    active = !active
  }

  function logout() {
    auth.logout($auth0Client)
  }

  // function languageChange() {
  //   $icelandic = !$icelandic
  // }
  //TODO this needs to be changed depending on if you are running developer or production
  const url = 'https://skraning.slipp.is/'
  //const url = 'http://localhost:5000/'
</script>

<nav class="navbar is-dark" aria-label="main navigation" style="padding:0px 10px">
  <div class="navbar-brand">
    <a class="navbar-item" href="/vessel">
      <img src="https://www.slippurinndng.is/static/files/logo/logo.svg" alt="Slippurinn logo" />
    </a>

    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div role="button" class="navbar-burger" class:is-active="{active}" aria-label="menu" aria-expanded="false" data-target="navbarMenu" on:click="{toggleBurger}">
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </div>
  </div>

  <div id="navbarMenu" class="navbar-menu" class:is-active="{active}">
    <div class="navbar-start">
      <a class="navbar-item" href="/vessel" on:click="{toggleBurger}">Þilfarskip </a>
      <a class="navbar-item" href="/owner" on:click="{toggleBurger}">Útgerðaraðilar </a>
      <a class="navbar-item" href="/vessels-in-process" on:click="{toggleBurger}">Sáturlisti </a>
      <a class="navbar-item" href="/all-dockings" on:click="{toggleBurger}">Verkefnalisti </a>
    </div>

    <div class="navbar-end">
      <!-- <p class="navbar-item">{$user.name}</p> -->
      <!-- <a href="/" class="navbar-item" on:click="{logout}"> <button class="button is-danger is-light">Logout</button> </a> -->
      <!-- <a class="navbar-item" href="" on:click="{languageChange}">{$icelandic ? 'Íslenska' : 'English'}</a> -->
      <a href="{`https://dev-vxl-0-we.eu.auth0.com/v2/logout?returnTo=${url}&client_id=LHkjv3uSGoThlvNleX38GBxB04tIciu5`}" class="navbar-item" on:click="{logout}">Útskrá </a>
    </div>
  </div>
</nav>
