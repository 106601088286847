<script>
  import { onMount } from 'svelte'
  import { token, user } from '../../stores'
  import { getVesselById } from '../../services/vessel'
  import Icon from 'svelte-awesome'
  import { refresh } from 'svelte-awesome/icons'
  import VesselDetailTable from '../components/VesselDetailTable.svelte'
  import VesselDetailTableSkutan from '../components/VesselDetailTableSkutan.svelte'
  import { updateOrCreateVessel } from '../../services/vessel'
  import { createEventDispatcher } from 'svelte'

  export let currentRoute
  let routeVesselId = currentRoute.namedParams.vesselId
  let loading = true
  let vessel = {}
  let reversedVesselComments = []
  let userCommentToSave
  let currentAmountOfCommentsToShow = 1

  onMount(async () => {
    //Gets a specific vessel by the id in the route / search params
    const response = await getVesselById($token, routeVesselId)
    if (response) {
      loading = false
      vessel = response
      //Create a separate comment array so that we can reverse the values without messing with the original commentarray 
      reversedVesselComments = vessel.userComment.comments.slice()
      reversedVesselComments.reverse()
      // console.log(reversedVesselComments)
    } else {
      console.log('ERROR getting vessel by id :', routeVesselId)
    }
  })

  const dispatch = createEventDispatcher()
  async function saveComment() {
    let dateOfComment = new Date()
    //if vessel.usercomment is empty init an array
    if (Object.keys(vessel.userComment).length <= 0) {
      vessel.userComment.comments = []
    }
    //push comment and data to the comments array
    //vessel.userComment.comments = []
    vessel.userComment['comments'].push({ id: Object.keys(vessel.userComment.comments).length, comment: userCommentToSave, time: dateOfComment, userMail: $user.email, userName: $user.name })
    //Update the database
    const success = await updateOrCreateVessel($token, vessel, true)
    //Reset the commentstring
    //console.log(vessel)
    userCommentToSave = ''
    if (success) {
      handleVesselUpdate()
      dispatch('vesselUpdate', { vessel: vessel })
    }
  }

  async function handleVesselUpdate() {
    const newVesselResponse = await getVesselById($token, routeVesselId)
    if (newVesselResponse) {
      vessel = newVesselResponse
      //Reverse the comment list 
      reversedVesselComments = vessel.userComment.comments.slice()
      reversedVesselComments.reverse()
    } else {
      console.log('ERROR creating or updating a vessel')
    }
  }
</script>

{#if loading}
  <div class="section">
    <Icon spin data="{refresh}" scale="3" />
  </div>
{:else}
  <div class="container">
    <div class="columns" style="">
      <div class="column">
        <div class="field">
          <label for="details" class="title is-4">Comment</label>
          <div class="control">
            <textarea id="details" class="textarea " style="resize:none" placeholder="Setja comment..." rows="{4}" bind:value="{userCommentToSave}"></textarea>
          </div>
          <div class="level-left" style="align-items: end; padding-top: 1rem">
            <button class="button is-success" on:click="{() => saveComment()}">Vista comment</button>
          </div>
        </div>
        <div>
          {#if vessel.userComment && vessel.userComment.comments.length > 0}
              {#each reversedVesselComments.slice(0, currentAmountOfCommentsToShow) as comment}
                <div class="message">
                  <div class="message-body">
                    <div class="content">
                      <p style="margin-bottom: 0em;">
                        <strong>{comment.userName}</strong> <small>{comment.userMail}</small> <small>{new Date(comment.time).toLocaleString()}</small>
                      </p>
                      <pre style="background-color: inherit; padding: 0; font: inherit">{comment.comment}</pre>
                    </div>
                  </div>
                </div>
              {/each}
            {#if currentAmountOfCommentsToShow < Object.keys(vessel.userComment.comments).length}
              <button on:click="{() => (currentAmountOfCommentsToShow = currentAmountOfCommentsToShow + 2)}" class="button is-ghost"> Sjá meira </button>
            {/if}
            {#if currentAmountOfCommentsToShow > 2}
              <button on:click="{() => (currentAmountOfCommentsToShow = 1)}" class="button is-ghost"> Sjá minna </button>
            {/if}
          {:else}
            <div class="message">
              <div class="message-body">
                <div class="content">
                  <p style="background-color: inherit; padding: 0; font: inherit">Enginn komment hafa verið gerð...</p>
                </div>
              </div>
            </div>
          {/if}
        </div>
      </div>
    </div>
    <br />
    <div class="columns">
      <div class="column">
        <div class="title is-4">Úpplýsingar af skútan skipaskrá</div>
        <hr />
        <VesselDetailTableSkutan detail="{vessel.inspections.inspections}" />
      </div>
    </div>
    <br />
    <div class="columns">
      <div class="column">
        <div class="title is-4">Úpplýsingar af skipaskrá</div>
        <p>{vessel.seaLicenseDates}</p>
        <hr />
        <VesselDetailTable detail="{vessel.equipmentInspection.equipment_inspection}" extraDetail="{vessel.equipmentInspection.equipment_inspection_extra}" />
        <VesselDetailTable detail="{vessel.lifeboats.lifeboats}" extraDetail="{vessel.lifeboats.lifeboats_extra}" />
        <VesselDetailTable detail="{vessel.liability.liability}" extraDetail="{vessel.liability.liability_extra}" />
        <VesselDetailTable detail="{vessel.bodyInspection.body_inspection}" extraDetail="{vessel.bodyInspection.body_inspection_extra}" />
        <VesselDetailTable detail="{vessel.axleInspection.axle_inspection}" extraDetail="{vessel.axleInspection.axle_inspection_extra}" />
        <VesselDetailTable detail="{vessel.machineInspection.machine_inspection}" extraDetail="{vessel.machineInspection.machine_inspection_extra}" />
        <VesselDetailTable detail="{vessel.craneInspection.crane_inspection}" extraDetail="{vessel.craneInspection.crane_inspection_extra}" />
        <VesselDetailTable detail="{vessel.electricalInspection.electrical_inspection}" extraDetail="{vessel.electricalInspection.electrical_inspection_extra}" />
        <VesselDetailTable detail="{vessel.extraInspection.extra_inspection}" extraDetail="{vessel.extraInspection.extra_inspection_extra}" />
        <VesselDetailTable detail="{vessel.technicalInspection.technical_inspection}" extraDetail="{vessel.technicalInspection.technical_inspection_extra}" />
        <VesselDetailTable detail="{vessel.thicknessMeasurement.thickness_measurement}" extraDetail="{vessel.thicknessMeasurement.thickness_measurement_extra}" />
      </div>
    </div>
  </div>
{/if}
