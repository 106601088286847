import axios from 'axios'
import { navigateTo } from 'svelte-router-spa'
import { toaster } from '../helpers'

//gets all docks for a specific vesselId
export async function getAllDocks(token) {
  try {
    const response = await axios.get(`${process.env.API_ROOT}/api/dock`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  } catch (e) {
    console.error(e)
  }
}

export async function getAllDocksProcess(token) {
  try {
    const response = await axios.get(`${process.env.API_ROOT}/api/dock/docking-process`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  } catch (e) {
    console.error(e)
  }
}

//gets all docks for a specific vesselId
export async function getAllDocksForVessel(token, routeVesselId) {
  try {
    const response = await axios.get(`${process.env.API_ROOT}/api/vesselInfo/${routeVesselId}/docking`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  } catch (e) {
    console.error(e)
  }
}

//creates dock and activates the toaster for both success and error
export async function createDock(token, routeVesselId, dockCreatedBy) {
  try {
    const response = await axios.post(
      `${process.env.API_ROOT}/api/vesselInfo/${routeVesselId}/docking/`,
      { vesselId: `${routeVesselId}`, created_by: `${dockCreatedBy}` },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    )
    let dockingId = response.data.dockingId
    const route = `vessel/${routeVesselId}/docking/${dockingId}?new=new`  //Send new as queryparam to know that this is a newlycreated dock
    navigateTo(route)
    let message = 'Docking created'
    toaster(message)
  } catch (e) {
    let message = e.response.data.message
    console.log(message)
    toaster(message)
  }
}

//updates a specific dock by the dock id and its vessel id
export async function updateDock(token, routeVesselId, routeDockingId, docking) {
  try {
    await axios.put(`${process.env.API_ROOT}/api/vesselInfo/${routeVesselId}/docking/${routeDockingId}`, docking, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    let message = `Docking updated`
    toaster(message)
  } catch (e) {
    console.error(e.message)
    toaster(e.message, true)
  }
}

export async function deleteDockById(token, routeVesselId, routeDockingId) {
  try {
    await axios.delete(`${process.env.API_ROOT}/api/vesselInfo/${routeVesselId}/docking/${routeDockingId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    let message = `Docking deleted`
    toaster(message)
    return true
  } catch (e) {
    console.error(e.message)
    toaster(e.message, true)
  }
}

//get dock by id for a specific vessel
export async function getDockById(token, routeVesselId, routeDockingId) {
  try {
    const response = await axios.get(`${process.env.API_ROOT}/api/vesselInfo/${routeVesselId}/docking/${routeDockingId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  } catch (e) {
    console.error(e)
  }
}
