<script>
  export let detail

  //if we are making the lifeboat table we need to make sure we only write every second row, since it returns weird rows, TODO LOOK into if we want to write the other data it returns
  // if (detail[0] && detail[0][0].includes('Gúmbátar')) {
  //   let tmpSpliced = []
  //   for (let i = 1; i < splicedDetail.length; i += 2) {
  //     tmpSpliced.push(splicedDetail[i])
  //   }
  //   splicedDetail = tmpSpliced
  // }
    function formatDate(date) {
      if (date == null) {
        return "Ekkert Skráð"
      }else
      return new Date(new Date(date).getTime() - (new Date().getTimezoneOffset() * 60000 )).toISOString().split("T")[0];
  }

</script>
<!-- {#if detail.length > 1} -->
 <p>Skírteini: {detail[0][4]}</p>
  <div style="padding-bottom:3em">
    <div style="padding-bottom:0px; border:1px solid hsl(0, 0%, 86%)">
      <table class="table is-striped is-narrow is-fullwidth ">
        <thead>
          <tr class="has-background-grey-lighter" style="text-align:left">
           <th>Skoðun</th> 
            <th>Skoðunar dagsetning</th> 
            <th>Lokadagsetning</th>
            <th>Tegund </th>
          </tr>
        </thead>
        <tbody>
          {#each detail as line}
            <tr style="text-align: left">       
              {#each line.slice(0,4) as data}
                {#if !isNaN(new Date(data).getTime())}
                  <td>{formatDate(data)}</td>
                {:else}
                   <td>{data}</td>
                {/if}
              {/each}
            </tr>
          {/each}
          
        </tbody>
      </table>
    </div>
    </div
  >
<!-- {:else} -->
  <div style="padding-bottom:3em"></div>
<!-- {/if} -->
