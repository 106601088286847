<script>
  import { Route } from 'svelte-router-spa'
  import { isAuthenticated } from '../../stores.js'
  import NestedNav from '../components/NestedNav.svelte'
  import Login from '../public/Login.svelte'
  export let currentRoute

  let paths
  let navArr = {}
  $: if (currentRoute) {
    //Replace the path strings with icelandic translations
    paths = currentRoute.path.split('/').filter((v) => v)
    let routeArr = currentRoute.path.split('/').filter((v) => v)
    for (let i = 0; i < paths.length; i++) {
      routeArr[i] += '/'
      routeArr[i] = i > 0 ? routeArr[i - 1] + routeArr[i] : routeArr[i]
      navArr[paths[i]] = routeArr[i]
    }
  }

  // function displayPath(p) {
  //   //Display yhe breadcrumb path with icelandic translations
  //   if (p == 'docking') {
  //     return 'Slipp upplýsingar'
  //   } else if (p == 'vessels-in-process') {
  //     return 'Sáturlisti'
  //   } else if (p == 'vessel') {
  //     return 'Þilfarskip'
  //   } else if (p == 'owner') {
  //     return 'Útgerðaraðilar'
  //   } else return p
  // }
</script>

{#if $isAuthenticated}
  <NestedNav />
  <section class="section" style="padding: 1rem 1rem">
    <div class="" style="padding: 0rem 0rem 2rem 0rem; max-width: 2100px; margin: auto">
      <!-- <div class="container is-fluid" style="padding: 1rem 1.5rem"> -->

      <!-- <div class="breadcrumb" aria-label="breadcrumbs" style="margin-bottom: 0.5rem; padding-left: 6.9rem;">
        <ul style="border-bottom: 1px solid #dbd9d9; width: 400px;">
          {#each paths as p}
            <li on:click="{() => (p == 'docking' ? '' : navigateTo(`${navArr[p]}`))}"> <a>{displayPath(p)}</a> </li>
          {/each}
        </ul>
      </div> -->

      <Route currentRoute="{currentRoute}" />
    </div>
  </section>
{:else}
  <Login />
{/if}
