<script>
  import { afterUpdate, onMount } from 'svelte'
  import AutoComplete from 'simple-svelte-autocomplete'
  import { createEventDispatcher } from 'svelte'
  import { FormField } from '../../helpers'
  import { token, user } from '../../stores'
  import { getAllPostCodes } from '../../services/postCode'
  import { navigateTo } from 'svelte-router-spa'
  import { deleteOwnerById, updateOrCreateOwners } from '../../services/owner'

  export let companyList = []
  export let showModal
  export let company = {}
  export let modify = false

  let postCodeList
  let appUrl = 'https://skraning.slipp.is/roles'
  let selectedPostCode = {}

  let ktField = new FormField('', '', false, true)

  onMount(async () => {
    const response = await getAllPostCodes($token)
    if (response) {
      postCodeList = response
    } else {
      console.log('ERROR getting all post-codes ')
    }
  })

  afterUpdate(() => {
    if (modify) {
      selectedPostCode = postCodeList.find((i) => i.postCode == company.postCode)
    } else if (company.id) {
      company = {}
      selectedPostCode = {}
    }
  })

  const dispatch = createEventDispatcher()

  function isFormValid() {
    ktField.class = 'is-danger'
    ktField.showHelpText = true

    if (company && company.kennitala) {
      let ckt = company.kennitala.replace('-', '')
      // Naive kennitala regex check. Just to check off simple errors
      let regex = /([0-6][0-9]|7[0-1])(0[1-9]|1[0-2])([0-9]{5})(0|9)/
      if (ckt.search(regex) == -1 || ckt.length != 10) {
        ktField.helpText = 'Kennitala must be valid and contain 10 numbers'
        return false
      } else if (companyList.find((c) => c.kennitala == company.kennitala && c.id != company.id)) {
        ktField.helpText = 'Owner already registered with kennitala'
        return false
      }
    } else if (ktField.required) {
      ktField.helpText = 'Kennitala is required'
      return false
    }

    ktField = new FormField('', '', false, true)
    return true
  }

  //When the modal data is saved and owner either created or updated
  async function sendCompany() {
    if (isFormValid()) {
      if (!company.postCode) {
        delete company.postCode
      }
      //If a company is either created or updated
      //TODO might be cleaner to split in two seperate service calls, put and delete, - But fine for now as it cleans up alot of space in this file
      await updateOrCreateOwners($token, company, modify, dispatch)
      company = {}
      closeModal()
    }
  }

  function closeModal() {
    ktField = new FormField('', '', false, true)
    dispatch('modalClosed')
  }

  async function deleteOwner() {
    if(confirm("Viltu EYÐA þessum útgerðaraðila? / Do you want to DELETE this owner")){
          if ($user[appUrl][0] == 'SuperUser') {
      const response = await deleteOwnerById($token, company.id)
      if (response) {
        closeModal()
        navigateTo(`/owner`)
      } else {
        console.log('ERROR deleting vessel by id ', company.id)
      }
    }
    }else{
      console.log("User canceled the delete action")
    }

  }
</script>

<div class="{showModal ? 'modal is-active' : 'modal'}">
  <div class="modal-background"></div>
  <div class="modal-card" style="height: 950px;">
    <header class="modal-card-head">
      <p class="modal-card-title">{modify ? 'Uppfæra' : 'Stofna'} Útgerðaraðila</p>

      <button class="delete "  on:click="{() => {
            closeModal()
          }}" aria-label="close"></button>
    </header>
    <section class="section modal-card-body large">
      <div class="field">
        <label for="name" class="label">Nafn</label>
        <div class="control">
          <input id="name" class="input" type="text" placeholder="Name" bind:value="{company.name}" />
        </div>
      </div>
      <div class="field">
        <label for="kennitala" class="label" style="display: flex; gap: 0.3rem; margin-left: -1rem;"><p style="color:red; font-size: 20px; position: relative; top: -4px ">*</p><p>Kennitala</p></label>
        <div class="control">
          <input id="kennitala" class="input is-primary required {ktField.class}" type="text" placeholder="Kennitala" bind:value="{company.kennitala}" />
          <p class="help {ktField.class}">{ktField.helpText}</p>
        </div>
      </div>

      <div class="field">
        <label for="address" class="label">Heimilisfang</label>
        <div class="control">
          <input id="address" class="input" type="text" placeholder="Address" bind:value="{company.address}" />
        </div>
      </div>
      <div class="field">
        <label for="postCode" class="label">Póstnúmer</label>
        <div class="control">
          <AutoComplete
            items="{postCodeList}"
            labelFieldName="postCode"
            labelFunction="{(item) => {
              if (item && item.postCode && item.place) {
                return item.postCode + ' ' + item.place
              }
              return ''
            }}"
            valueFieldName="postCode"
            placeholder="Post Code"
            valueFunction="{(item) => {
              if (item && item.postCode) {
                return item.postCode
              }
              return
            }}"
            bind:selectedItem="{selectedPostCode}"
            bind:value="{company.postCode}"
            showClear="{true}"
          />
        </div>
      </div>
    </section>

    <footer class="modal-card-foot center">
      <div class="buttons">
        <button class="button is-success " on:click="{() => sendCompany()}">{modify ? 'Update' : 'Create'}</button>
        <button
          class="button "
          on:click="{() => {
            closeModal()
          }}">Cancel</button
        >
              {#if modify}
        {#if $user[appUrl] ? $user[appUrl][0] == 'SuperUser' : false}
          <button
            class="button is-danger is-outlined"
            on:click="{() => {
              deleteOwner()
            }}">Delete</button
          >
        {/if}
      {/if}
      </div>
    </footer>
  </div>
</div>
