<script>
  import FullCalendar from 'svelte-fullcalendar'
  import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
  import daygridPlugin from '@fullcalendar/daygrid'
  import timelinePlugin from '@fullcalendar/timeline'
  import timegridPlugin from '@fullcalendar/timegrid'
  import interactionPlugin from '@fullcalendar/interaction'
  import { onMount } from 'svelte'

  import { greyOut, lightBlue, lightGreen, lightRed, lightYellow, northBorder, southBorder } from '../../constants/colors'
  import { navigateTo } from 'svelte-router-spa'
  import { token } from '../../stores'
  import Icon from 'svelte-awesome'
  import { refresh } from 'svelte-awesome/icons'
  import { getAllDocksProcess } from '../../services/dock'

  // export let allDockingInfo
  let setEvents = []
  let setRes = []
  let options
  let dockingColor
  let eventBorderColor
  let dockName
  let allDockingInfo
  let loading = true
  onMount(async () => {
    //Get dock by id
    //Set the events in correct format and only if the docking procedure has a start date
    const response = await getAllDocksProcess($token)
    if (response) {
      allDockingInfo = response
      loading = false
      allDockingInfo = allDockingInfo.filter((v) => v.inProcess)
    } else {
      console.log('ERROR getting dockins ', response)
    }

    for (let i = 0; i < allDockingInfo?.length; i++) {
      if (allDockingInfo[i].startDate != null) {
        dockName = allDockingInfo[i].dock
        dockingColor = `${greyOut}` //if no dock is selected then the color is grey for visual feedback
        if (dockName == null || dockName == '') {
          dockName = 'Ekkert valið'
          eventBorderColor = `${greyOut}`
        }
        if (dockName == 'Aðaldráttarbraut') {
          dockingColor = `${lightBlue}`
          eventBorderColor = dockingColor
        }
          if (dockName == 'Aðaldráttarbraut (Norður)') {
          dockingColor = `${lightBlue}`
          eventBorderColor = `${northBorder}`
        }
          if (dockName == 'Aðaldráttarbraut (Suður)') {
          dockingColor = `${lightBlue}`
          eventBorderColor = `${southBorder}`
        }
        if (dockName == 'Litla dráttarbraut') {
          dockingColor = `${lightGreen}`
          eventBorderColor = dockingColor
        }
        if (dockName == 'Litla dráttarbraut (Norður)') {
          dockingColor = `${lightGreen}`
          eventBorderColor = `${northBorder}`
        }
        if (dockName == 'Litla dráttarbraut (Suður)') {
          dockingColor = `${lightGreen}`
          eventBorderColor = `${southBorder}`
        }
        if (dockName == 'Flotkví') {
          dockingColor = `${lightYellow}`
          eventBorderColor = dockingColor
        }
        if (dockName == 'Bryggjukantur') {
          dockingColor = `${lightRed}`
          eventBorderColor = dockingColor
        }
        
        //Creates the event that will be positioned in the calendar  title: allDockingInfo[i].vesselName + ' , Staðsetning: ' + dockName
        setRes.push({id: `vessel/${allDockingInfo[i].vesselId}/docking/${allDockingInfo[i].id}`, vessel: dockName, title: allDockingInfo[i].vesselName, start: allDockingInfo[i].startDate,})
        //Before slipptaka
        if(allDockingInfo[i].startDate != allDockingInfo[i].slippDate){
          setEvents.push({ resourceId: `vessel/${allDockingInfo[i].vesselId}/docking/${allDockingInfo[i].id}` ,title: allDockingInfo[i].vesselName + " (Fyrir slipptöku)", start: allDockingInfo[i].startDate, end: allDockingInfo[i].slippDate, borderColor: greyOut, eventBackgroundColor: "black" ,color: "white", textColor: greyOut, id: `vessel/${allDockingInfo[i].vesselId}/docking/${allDockingInfo[i].id}` })
        }
        //After slipptaka 
        setEvents.push({resourceId: `vessel/${allDockingInfo[i].vesselId}/docking/${allDockingInfo[i].id}`, title: allDockingInfo[i].vesselName, start: allDockingInfo[i].slippDate, end: allDockingInfo[i].conclusionDate, background: dockingColor, borderColor: eventBorderColor, color: dockingColor, textColor: 'black', id: `vessel/${allDockingInfo[i].vesselId}/docking/${allDockingInfo[i].id}` })
        //After niðursetning 
        if(allDockingInfo[i].conclusionDate != allDockingInfo[i].endDate){
          setEvents.push({ resourceId: `vessel/${allDockingInfo[i].vesselId}/docking/${allDockingInfo[i].id}`, title: allDockingInfo[i].vesselName + " (Eftir slipptöku)", start: allDockingInfo[i].conclusionDate , end: allDockingInfo[i].endDate, borderColor: greyOut, color: "white", textColor: greyOut, id: `vessel/${allDockingInfo[i].vesselId}/docking/${allDockingInfo[i].id}` })
        }
      }

    }

    function handleEventClick(event) {
      navigateTo(event.event.id)
    }

    //Gets the number of days passed this year so we can position the scrollbar at the correct place when opening the year view
    function daysInYear(date) {
      let daysThisYear = (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) - Date.UTC(date.getFullYear(), 0, 0)) / 24 / 60 / 60 / 1000
      return (daysThisYear - 2) * 24 * 60 * 60 * 1000
    }

    //options for full calendar
    options = {
      selectable: false,
      selectMirror: false,
      nowIndicator: true,
      selectOverlap: false,
      editable: false,
      weekends: true,
      eventOrder: "start",
      schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source', //Key to use the calander for free
      eventClick: handleEventClick,
      events: setEvents,
      height: '85vh',
      initialView: 'resourceTimelineYear',
      timeZone: 'GMT',
      scrollTime: daysInYear(new Date()),
      slotLabelFormat: [{ month: 'long' }, { day: 'numeric' }, { weekday: 'short' }],
      resources: setRes,
      resourceGroupField: "vessel",
      resourceOrder: "start",
      filterResourcesWithEvents: true,
      resourceAreaWidth: "15%",
      resourceAreaHeaderContent: "Skip",
      plugins: [daygridPlugin, timegridPlugin, interactionPlugin, resourceTimelinePlugin, timelinePlugin],
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'resourceTimelineYear,resourceTimelineMonth,resourceTimelineDay',
      },
    }
  })

</script>

{#if loading}
  <div class="section">
    <Icon spin data="{refresh}" scale="3" />
  </div>
{:else}
  <div class="demo-app">
    <div class="demo-app-calendar">
      <FullCalendar options="{options}" style="height: unset;" />
      <div class="dockNameOverContainer">
        <div class="dockNameContainer"><div class="dockName" style="{`background-color:${lightYellow}`}"></div><p>FlotKví</p></div>
        <div class="dockNameContainer"><div class="dockName" style="{`background-color:${lightBlue}`}"></div><p>Aðaldráttarbraut</p></div>
        <div class="dockNameContainer"><div class="dockName" style="{`background-color:${lightGreen}`}"></div><p>Litla dráttarbraut</p></div>
        <div class="dockNameContainer"><div class="dockName" style="{`background-color:${lightRed}`}"></div><p>Bryggjukantur</p></div>
      </div>
      <div class="dockNameOverContainer">
        <div class="dockNameContainer"><div class="dockName" style="{`border: 2px dashed ${northBorder}`}"></div><p>Norðan meginn</p></div>
        <div class="dockNameContainer"><div class="dockName" style="{`border: 2px dashed ${southBorder}`}"></div><p>Sunnan meginn</p></div>
      </div>
    </div>
  </div>
{/if}

<style>
  :global(* > *) {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  .dockName {
    width: 20px;
    height: 80%;
    border: rgba(0, 0, 0, 0.2) 1px solid;
  }

  :global(a.fc-h-event) {
    border: 2px dashed black;
    /* background: repeating-linear-gradient(
      45deg,
    ); */
  } 
  
  .dockNameOverContainer {
    display: flex;
    gap: 20px;
    padding: 10px;
    justify-content: center;
    align-items: center;
  }
  .dockNameContainer {
    display: flex;
    gap: 5px;
    justify-content: center;
    height: 30px;
    align-items: center;
  }
  .demo-app {
    max-width: 1900px;
    min-width: 690px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
    font-size: 14px;
    border-radius: 10px;
    /* border: #888888 solid 1px; */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    margin: 20px;
    padding: 20px;
    padding-bottom: 0px;
    margin-bottom: 5rem;
    margin-top: 5rem;
    width: 100%;
  }
  .demo-app-calendar {
    width: 100%;
    flex-grow: 1;
    margin: 0 auto;
    max-width: 1900px;
  }
</style>
