<script>
  export let extraDetail
  export let detail
  let splicedDetail = detail.splice(2)

  //if we are making the lifeboat table we need to make sure we only write every second row, since it returns weird rows, TODO LOOK into if we want to write the other data it returns
  if (detail[0] && detail[0][0].includes('Gúmbátar')) {
    let tmpSpliced = []
    for (let i = 1; i < splicedDetail.length; i += 2) {
      tmpSpliced.push(splicedDetail[i])
    }
    splicedDetail = tmpSpliced
  }
</script>

<p>{detail[0]}</p>

{#if detail.length > 1}
  <div style="padding-bottom:3em">
    <div style="padding-bottom:0px; border:1px solid hsl(0, 0%, 86%)">
      <table class="table is-striped is-narrow is-fullwidth ">
        <thead>
          <tr class="has-background-grey-lighter" style="text-align:left">
            {#each detail[1] as header}
              <th>{header}</th>
            {/each}
          </tr>
        </thead>
        <tbody>
          {#each splicedDetail as row}
            <tr style="text-align: left">
              {#each row as data}
                <td>{data}</td>
              {/each}
            </tr>
          {/each}
        </tbody>
      </table>
    </div>
    {#if extraDetail.length > 0}
      <div class="box" style="border:1px solid hsl(0, 0%, 86%)">
        <p style="font-weight:bold">Auka athugasemd:</p>
        <p> {extraDetail[0]}</p>
      </div>
    {/if}</div
  >
{:else}
  <div style="padding-bottom:3em"></div>
{/if}
