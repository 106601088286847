//import Home from './views/public/Home.svelte'
import Login from './views/public/Login.svelte'
import AdminLayout from './views/admin/AdminLayout.svelte'
import VesselOverview from './views/admin/VesselOverview.svelte'
import Vessel from './views/admin/Vessel.svelte'
import DockingOverview from './views/admin/DockingOverview.svelte'
import Docking from './views/admin/Docking.svelte'
import NotFound from './views/public/NotFound.svelte'
import OwnerOverview from './views/admin/OwnerOverview.svelte'
import OverviewVesselsInProcess from './views/admin/OverviewVesselsInProcess.svelte'
import OverviewAllDockings from './views/admin/OverviewAllDockings.svelte'


const routes = [
  { name: '/', component: Login },
  { name: '/vessel', layout: AdminLayout, component: VesselOverview },
  {
    name: '404',
    component: NotFound,
    route: '404',
  },

  { name: '/vessels-in-process', layout: AdminLayout, component: OverviewVesselsInProcess },
  { name: '/all-dockings', layout: AdminLayout, component: OverviewAllDockings },

  { name: '/owner', layout: AdminLayout, component: OwnerOverview },

  {
    name: '/vessel/:vesselId',
    layout: AdminLayout,
    component: Vessel,
    nestedRoutes: [
      { name: 'index', component: DockingOverview },
      { name: '/docking/:dockingId', component: Docking },
    ],
  },
]

export { routes }
