<script>
  import { auth0Client } from '../../stores.js'
  import auth from '../../authService'

  function login() {
    auth.loginWithPopup($auth0Client)
  }
</script>

<section class="section" style="padding: 1rem 1.5rem">
  <div class="container" style="padding: 1rem 1.5rem">
    <div class="column" style="background-color: #00498f, margin-left:auto, margin-right: auto, display: block">
      <img src="https://www.slippurinndng.is/static/files/logo/logo.svg" alt="Slippurinn logo" />
    </div>
    <div class="column" style="background-color: #00498f">
      <div class="notification" style="padding: 0">
        <div class="content">
          <br />
          <p class="title is-2 has-text-centered">Slippskráning</p>
          <!-- <hr style="border: 1px solid black; margin: 0" /> -->
          <div style="margin-left:auto; margin-right: auto; display: table">
            <img src="https://www.slippurinndng.is/static/files/logo/logo.svg" alt="Slippurinn logo" />
          </div>
          <!-- <hr style="border: 1px solid black; margin: 0; margin-top: -6px" /> -->
          <br />
          <div class="mainContent">
            <button class="button is-link is-outlined is-medium " on:click="{login}">
              <span class="icon">
                <img width="{25}" height="{25}" src="https://cdn.pixabay.com/photo/2013/02/12/09/07/microsoft-80660_1280.png" alt="microsoft logo" />
              </span>
              <span>Innskráning</span>
            </button>
            <p class=" has-text-centered">Þú getur skráð þig inn með @slipp netfanginu þínu</p>
          </div>
          <br />
          <div class="column">
            <p class="has-text-centered">Hafa samband: info@slipp.is</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<style>
  .mainContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
</style>
