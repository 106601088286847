import { toast } from 'bulma-toast'

export function toaster(message = '', danger = false) {
  toast({
    message: message,
    duration: danger ? 5000 : 2000,
    position: 'top-center',
    type: danger ? 'is-danger' : 'is-success',
    dismissible: true,
    extraClasses: 'mt-6',
  })
}

export class FormField {
  constructor(inputClass = '', helpText = '', showHelpText = false, required = false) {
    this.inputClass = inputClass
    this.helpText = helpText
    this.showHelpText = showHelpText
    this.required = required
  }
}
