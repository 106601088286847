<script>
  import { onMount } from 'svelte'
  import { navigateTo } from 'svelte-router-spa'
  import Icon from 'svelte-awesome'

  import AutoComplete from 'simple-svelte-autocomplete'
  import { check } from 'svelte-awesome/icons'
  import SveltyPicker from 'svelty-picker'

  import { toaster } from '../../helpers'
  import { token } from '../../stores'
  import { deleteDockById, getAllDocks, updateDock } from '../../services/dock'
  import { getDockById } from '../../services/dock'
  import VesselInformation from './VesselInformation.svelte'
  import { getVesselById, sendMail, updateOrCreateVessel } from '../../services/vessel'
  import { statusDecided, statusOffer, statusOrder, statusFinished, statusAsking, statusDidNotCome, checkboxGreen, statusOfferText, statusAskingText, statusDidNotComeText, statusOrderText } from '../../constants/colors'

  export let currentRoute

  let vessel = {}
  let routeVesselId = currentRoute.namedParams.vesselId
  let routeDockingId = currentRoute.namedParams.dockingId
  let docking
  let dockList = []
  let loading = true
  let modify = false
  let statusList = ['Ákveðið', 'Tilboð', 'Pöntun', 'Fyrirspurn', 'Í vinnslu', 'Til minnis', 'Tilboð sent', 'Kom ekki', 'Geymsla', 'Lokið']
  let dockingLocationList = ['Slippurinn Akureyri', 'Hafnafjörður', 'Vestmanneyjar', 'Reykjavík', 'Njarðvík', 'Stykkishólmur', 'Færeyjar', 'Danmörk', 'Pólland', 'Annað']
  onMount(async () => {
    //Get dock by id
    const response = await getDockById($token, routeVesselId, routeDockingId)
    if (response) {
      docking = response
      loading = false
      checkIfNew()
    } else {
      console.log('ERROR getting dock by id ', routeDockingId)
    }
    //error checking
    if (!routeDockingId || !docking) {
      let message = `No docking ${!routeDockingId ? 'ID found' : 'found with ID ' + routeDockingId}. Redirecting to vessel`
      toaster(message, true)
      navigateTo(`vessel/${routeVesselId}`)
    }

    //gett all docks
    const responseDocks = await getAllDocks($token)
    if (responseDocks) {
      dockList = responseDocks
    } else {
      console.log('ERROR getting all docks ')
    }
  })
  function toggleModify() {
    modify = !modify
  }

  //When in process is flipped on update the docking info
  async function setInProcess() {
    docking.inProcess = !docking.inProcess
    //Just send email if its been added, no need to send if its removed from inProcess
    if (docking.inProcess) {
      const vessel = await getVesselById($token, routeVesselId)
      sendMail($token, docking, vessel.name)
    }
    await updateDock($token, routeVesselId, routeDockingId, docking)
  }

  //Check if the dock is newly created
  function checkIfNew() {
    //We know that this dock is newly created so we eneable editing as soon as we open
    if (currentRoute.queryParams && currentRoute.queryParams.new == 'new') {
      modify = true
    } else {
      modify = false
    }
  }
  //If cancel button is pressed when updating a specific dock
  async function cancel() {
    const response = await getDockById($token, routeVesselId, routeDockingId)
    if (response) {
      docking = response
      checkIfNew()
    } else {
      console.log('ERROR getting dock by id ', routeDockingId)
    }
    modify = false
  }

  function isFormValid() {
    //need to check if the autocomplete values were unpicked since then it returns undefined we need to change that to null
    if (docking.dock === undefined) {
      docking.dockId = null
    }
    if (docking.status === undefined) {
      docking.status = null
    }

    // Only need to check whether the dates are valid if two or more dates have been selected.
    // Start by nullifying empty dates and then creating new Date objects for the comparisons.
    nullifyEmptyDates()
    nullifyObjects()
    return true
  }

  function nullifyEmptyDates() {
    docking.slippDate = docking.slippDate ? docking.slippDate : null
    docking.startDate = docking.startDate ? docking.startDate : null
    docking.conclusionDate = docking.conclusionDate ? docking.conclusionDate : null
    docking.endDate = docking.endDate ? docking.endDate : null
  }

  function nullifyObjects() {
    //if objects are empty we need to nullifie them since the picker returns value "undifined "
    if (docking.dock === undefined) {
      docking.dock = { id: null, name: null }
    }
  }

  function getStatusColor(status) {
    //TODO Change the status to its own db table that holds the colors
    if (status === null) {
      return '#00000; border: black 1px solid'
    }
    if (status.toString().trim() === 'Ákveðið') {
      return `background-color:${statusDecided}; color: white;`
    } else if (status.toString().trim() === 'Tilboð') {
      return `background-color:${statusOffer}; color: ${statusOfferText};`
    } else if (status.toString().trim() === 'Pöntun') {
      return `background-color:${statusOrder}; color: ${statusOrderText};`
    } else if (status.toString().trim() === 'Lokið') {
      return `background-color:${statusFinished}; color: white;`
    } else if (status.toString().trim() === 'Fyrirspurn') {
      return `background-color:${statusAsking}; color: ${statusAskingText};`
    } else if (status.toString().trim() === 'Kom ekki') {
      return `background-color:${statusDidNotCome}; color: ${statusDidNotComeText};`
    } else {
      return 'border: black 1px solid'
    }
  }

  async function deleteDock() {
    const response = await deleteDockById($token, routeVesselId, routeDockingId)
    if (response) {
      navigateTo(`/vessel/${routeVesselId}`)
    } else {
      console.log('ERROR deleting dock by id ', routeDockingId)
    }
  }

  //If Save button is pressed when updating a specific dock
  async function save() {
    if (!isFormValid()) {
      return
    }
    if (docking.inProcess === true) {
      vessel.dock = docking.dock
      vessel.id = docking.vesselId
      await updateOrCreateVessel($token, vessel, true)
    }
    await updateDock($token, routeVesselId, routeDockingId, docking)
    modify = false
  }

  //Sets initial date for the datepickers,
  //Example if users picks start date in 2023 then the rest of the dates will start at that date,
  //this makes it more user friendly to select dates that are far in the future 
  function setInitial(theDate, dateToCha){
    if(theDate){
      //console.log(docking.startDate)
      let date = new Date(theDate)
      date.setDate(date.getDate() + 10)
      switch (dateToCha){
        case "start":
          docking.slippDate = new Date(date).toISOString().slice(0, 10);
          break;
        case "slipp":
          docking.conclusionDate = new Date(date).toISOString().slice(0, 10);
          break;
        case "conc":
          docking.endDate = new Date(date).toISOString().slice(0, 10);
          break;
      } 
      return date
    } else {
      return '---'
    }
  }
</script>

{#if loading}
  <div> </div>
{:else}
  <div class="{modify ? 'dockingTopLevelModify' : 'dockingTopLevel'}">
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <div class="title is-4">Slipp upplýsingar</div>
        </div>
        {#if modify}
          <button class="button is-danger" on:click="{() => deleteDock()}">Eyða verkefni</button>
        {/if}
      </div>
      <div class="level-right">
        <div class="level-item">
          {#if !modify}
            <button class="{!docking.inProcess ? 'button is-success' : 'button is-danger'}" on:click="{() => setInProcess()}">{!docking.inProcess ? 'Setja í sáturlista' : 'Fjarlægja úr Sáturlista'}</button>
          {/if}
        </div>
        <div class="level-item">
          {#if !modify}
            <button class="button is-info" on:click="{() => toggleModify()}">Uppfæra upplýsingar</button>
          {:else}
            <buttons class="buttons has-addons">
              <button class="button is-warning" on:click="{() => cancel()}">Cancel</button>
              <button class="button is-success" on:click="{() => save()}">Save</button>
            </buttons>
          {/if}
        </div>
      </div>
    </div>

    <div class="table-container">
      <table class="{`table is-fullwidth `}">
        <thead>
          <tr class="px-0" style="text-align:center">
            <th>Upptökumannvirki</th>
            <th>Upphaf verks</th>
            <th>Slipptaka</th>
            <th>Niðursetning</th>
            <th>Lok verks</th>
          </tr>
        </thead>
        <tbody>
          <tr style="text-align:center; ">
            <td nowrap class="dockInfoItemTopRow">
              {#if modify}
                <div class="field" style="height: 16rem">
                  <div class="control">
                    <AutoComplete items="{dockList}" labelFieldName="name" valueFieldName="id" bind:selectedItem="{docking.dock}" placeholder="Dock" showClear="{true}" />
                  </div>
                </div>
              {:else}
                <div>{docking.dock.name ? docking.dock.name : `---`}</div>
              {/if}
            </td>
            <td nowrap class="dockInfoItemTopRow">
              {#if modify}
                <SveltyPicker inputClasses="input date-picker" placeholder="{'---'}" endDate="{docking.slippDate ? docking.slippDate : docking.conclusionDate ? docking.conclusionDate : docking.endDate}" bind:value="{docking.startDate}" />
              {:else}
                {docking.startDate ? docking.startDate : '---'}
              {/if}
            </td>
            <td nowrap class="dockInfoItemTopRow">
              {#if modify}
              {#key docking.startDate}
                <SveltyPicker inputClasses="input date-picker" initialDate={docking.slippDate ?? (setInitial(docking.startDate, "start"))} placeholder={"---"} endDate="{docking.conclusionDate ? docking.conclusionDate : docking.endDate}" startDate="{docking.startDate}" bind:value={docking.slippDate}/>
              {/key}
                {:else}
                {docking.slippDate ? docking.slippDate : "---"}
              {/if}
            </td>
            <td nowrap class="dockInfoItemTopRow">
              {#if modify}
              {#key docking.slippDate}
                <SveltyPicker inputClasses="input date-picker" initialDate={docking.conclusionDate ?? setInitial(docking.slippDate, "slipp")}  placeholder={ "---"} startDate="{docking.slippDate ? docking.slippDate : docking.startDate}" endDate="{docking.endDate}" bind:value="{docking.conclusionDate}" />
                {/key}
              {:else}
                {docking.conclusionDate ? docking.conclusionDate : '---'}
              {/if}
            </td>
            <td nowrap class="dockInfoItemTopRow">
              {#if modify}
                  {#key docking.conclusionDate}
                <SveltyPicker inputClasses="input date-picker" initialDate={docking.endDate ?? setInitial(docking.conclusionDate, "conc")} placeholder={ "---"} startDate="{docking.conclusionDate ? docking.conclusionDate : docking.slippDate ? docking.slippDate : docking.startDate}" bind:value="{ docking.endDate}" />
                {/key}
              {:else}
                {docking.endDate ? docking.endDate : '---'}
              {/if}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <br />
    <div class="table-container">
      <table class="{`table is-fullwidth `}">
        <thead>
          <tr class="px-100" style="text-align:center">
            <th>Staða verks</th>
            <th>Staður slipps eða þjónustu</th>
            <th>Umsjón</th>
            <th>Tengiliðir Útgerðar</th>
            <th>Stofnandi</th>
          </tr>
        </thead>
        <tbody>
          <tr style="text-align:center; ">
            <td nowrap class="dockInfoItemMiddleRow">
              {#if modify}
                <div class="field" style="height: 21.5rem">
                  <div class="control">
                    <AutoComplete items="{statusList}" bind:selectedItem="{docking.status}" placeholder="Staða" showClear="{true}" />
                  </div>
                </div>
              {:else}
                <div style="{`${getStatusColor(docking.status)}; border: rgba(0,0,0,0.2) 1px solid; box-shadow: 1px 1px 1px rgba(0,0,0,0.5)`}" class="dockingStatus">{docking.status ? docking.status : '---'}</div>
              {/if}
            </td>
            <td nowrap class="dockInfoItemMiddleRow">
              {#if modify}
                <!-- <div class="control">
                <textarea id="dockingLocation" class="textarea " placeholder="Slippurinn Akureyri..." rows="5" readonly="{!modify}" bind:value="{docking.location}"></textarea>
              </div> -->
                <div class="field" style="height: 21.5rem">
                  <div class="control">
                    <AutoComplete items="{dockingLocationList}" bind:selectedItem="{docking.location}" placeholder="Staðsetning" showClear="{true}" />
                  </div>
                </div>
              {:else}
                {docking.location ? docking.location : '---'}
              {/if}
            </td>
            <td nowrap class="dockInfoItemMiddleRow">
              {#if modify}
                <div class="control">
                  <textarea id="dockingSupervision" class="textarea " placeholder="Jón...." rows="5" readonly="{!modify}" bind:value="{docking.supervision}"></textarea>
                </div>
              {:else}
                {docking.supervision ? docking.supervision : '---'}
              {/if}
            </td>
            <td nowrap class="dockInfoItemMiddleRow">
              {#if modify}
                <div class="control">
                  <textarea id="dockingContacts" class="textarea " placeholder="Jón, 86912345" rows="5" readonly="{!modify}" bind:value="{docking.contacts}"></textarea>
                </div>
              {:else}
                {docking.contacts ? docking.contacts : '---'}
              {/if}
            </td>
            <td nowrap class="dockInfoItemMiddleRow">
              {#if modify}
                <div class="control">
                  <textarea id="dockingCreatedBy" class="textarea " placeholder="name@slipp.is" rows="5" readonly="{!modify}" bind:value="{docking.createdBy}"></textarea>
                </div>
              {:else}
                {docking.createdBy ? docking.createdBy : '---'}
              {/if}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <br />
    <div class="title is-4">{'Hvað skal framkvæma'}</div>
    <div class="table-container">
      <table class="table is-fullwidth is-bordered">
        <thead>
          <tr class="px-0" style="text-align:center">
            <!-- <th>Slipptaka</th> -->
            <th>Botn og síðulokar </th>
            <th>Botnþvottur og málning</th>
            <th>Síður þvottur og málning</th>
            <th>Ofandekks þvottur og málning</th>
            <th>Tankahreinsun</th>
            <th>Öxuldráttur</th>
            <th>Öxulþétti</th>
            <th>Stýri</th>
            <th>Stýrisvél</th>
            <th>Anóður</th>
            <th>Gróðurvarnarskaut</th>
          </tr>
        </thead>
        <tbody>
          <tr style="text-align:center">
            <!-- <td>
            {#if modify}
              <input type="checkbox" bind:checked="{docking.docking}" />
            {:else if docking.docking}
              <Icon style="{`color: ${checkboxGreen}`}" data="{check}" />
            {/if}
          </td> -->
            <td>
              {#if modify}
                <input type="checkbox" bind:checked="{docking.seaValves}" />
              {:else if docking.seaValves}
                <Icon style="{`color: ${checkboxGreen}`}" data="{check}" />
              {/if}
            </td>
            <td>
              {#if modify}
                <input type="checkbox" bind:checked="{docking.bottomWash}" />
              {:else if docking.bottomWash}
                <Icon style="{`color: ${checkboxGreen}`}" data="{check}" />
              {/if}
            </td>
            <td>
              {#if modify}
                <input type="checkbox" bind:checked="{docking.deepWash}" />
              {:else if docking.deepWash}
                <Icon style="{`color: ${checkboxGreen}`}" data="{check}" />
              {/if}
            </td>
            <td>
              {#if modify}
                <input type="checkbox" bind:checked="{docking.topDeckWash}" />
              {:else if docking.topDeckWash}
                <Icon style="{`color: ${checkboxGreen}`}" data="{check}" />
              {/if}
            </td>
            <td>
              {#if modify}
                <input type="checkbox" bind:checked="{docking.tankCleaning}" />
              {:else if docking.tankCleaning}
                <Icon style="{`color: ${checkboxGreen}`}" data="{check}" />
              {/if}
            </td>
            <td>
              {#if modify}
                <input type="checkbox" bind:checked="{docking.propellerShaftOverhaul}" />
              {:else if docking.propellerShaftOverhaul}
                <Icon style="{`color: ${checkboxGreen}`}" data="{check}" />
              {/if}
            </td>
            <td>
              {#if modify}
                <input type="checkbox" bind:checked="{docking.shaftSeal}" />
              {:else if docking.shaftSeal}
                <Icon style="{`color: ${checkboxGreen}`}" data="{check}" />
              {/if}
            </td>
            <td>
              {#if modify}
                <input type="checkbox" bind:checked="{docking.rudderShaft}" />
              {:else if docking.rudderShaft}
                <Icon style="{`color: ${checkboxGreen}`}" data="{check}" />
              {/if}
            </td>
            <td>
              {#if modify}
                <input type="checkbox" bind:checked="{docking.rudderMachine}" />
              {:else if docking.rudderMachine}
                <Icon style="{`color: ${checkboxGreen}`}" data="{check}" />
              {/if}
            </td>
            <td>
              {#if modify}
                <input type="checkbox" bind:checked="{docking.zinkAnodes}" />
              {:else if docking.zinkAnodes}
                <Icon style="{`color: ${checkboxGreen}`}" data="{check}" />
              {/if}
            </td>
            <td>
              {#if modify}
                <input type="checkbox" bind:checked="{docking.cathelco}" />
              {:else if docking.cathelco}
                <Icon style="{`color: ${checkboxGreen}`}" data="{check}" />
              {/if}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="columns is-gapless">
      <div class="column is-two-fifths">
        <div class="field">
          <label for="details" class="label">Fleiri upplýsingar um slipp</label>
          <div class="control">
            <textarea id="details" class="textarea " placeholder="Fleiri upplýsingar um slipp..." rows="14" readonly="{!modify}" bind:value="{docking.details}"></textarea>
          </div>
        </div>
      </div>
    </div>
    {#if !modify}
      <hr />
    {/if}
  </div>
  <VesselInformation currentRoute="{currentRoute}" />
{/if}

<style>
  .dockInfoItemTopRow {
    white-space: normal;
    background-color: white;
    width: 20%;
    word-break: break-all;
  }
  .dockInfoItemMiddleRow {
    white-space: normal;
    background-color: white;
    width: 20%;
    word-break: break-all;
  }
  .dockingStatus {
    border-radius: 10px;
    width: 180px;
    margin: auto;
  }
  .dockingTopLevelModify {
    border-radius: 10px;
    /* border: #888888 solid 1px; */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    margin: 20px;
    padding: 20px;
    margin-bottom: 5rem;
    margin-top: 5rem;
  }
  .dockingTopLevel {
    margin-bottom: 5rem;
    /* margin-top: 5rem; */
    /* border-radius: 10px;
    border: #e0e0e0 solid 1px; 
    margin: -10px;
    padding: 10px; */
  }
</style>
