import axios from 'axios'
import { toaster } from '../helpers'
import { navigateTo } from 'svelte-router-spa'

//gets all vessel in the database
export async function getAllVessels(token) {
  try {
    const response = await axios.get(`${process.env.API_ROOT}/api/vesselInfo`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  } catch (e) {
    console.error(e)
  }
}

//gets a single specific vessel by its id
export async function getVesselById(token, routeVesselId) {
  try {
    const response = await axios.get(`${process.env.API_ROOT}/api/vesselInfo/${routeVesselId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  } catch (e) {
    console.error(e)
  }
}

export async function deleteVesselById(token, routeVesselId) {
  try {
    await axios.delete(`${process.env.API_ROOT}/api/vesselInfo/${routeVesselId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    let message = `Vessel deleted`
    toaster(message)
    return true
  } catch (e) {
    console.error(e.message)
    toaster(e.message, true)
  }
}

//Create a new vessel or update a vessel by id and return true if successful
export async function updateOrCreateVessel(token, vessel, modify) {
  try {
    if (modify) {
      await axios.put(`${process.env.API_ROOT}/api/vesselInfo/${vessel.id}`, vessel, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
    } else {
      await axios.post(`${process.env.API_ROOT}/api/vesselInfo`, vessel, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
    }
    const route = `vessel/${vessel.id}/`
    navigateTo(route)
    let message = `Success. Vessel ${vessel.id} ${modify ? ' updated' : ' created'}`
    toaster(message)
    return true
  } catch (e) {
    let message = e.response.data.message
    toaster(message, true)
  }
}

//gets all vessle-types
export async function getVesselTypes(token) {
  try {
    const response = await axios.get(`${process.env.API_ROOT}/api/vessel-type`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  } catch (e) {
    console.error(e)
  }
}

//gets all vessel materials
export async function getVesselMaterials(token) {
  try {
    const response = await axios.get(`${process.env.API_ROOT}/api/material`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  } catch (e) {
    console.error(e)
  }
}

//gets all vessel classes
export async function getVesselClasses(token) {
  try {
    const response = await axios.get(`${process.env.API_ROOT}/api/class`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  } catch (e) {
    console.error(e)
  }
}

//gets all vessel license
export async function getVesselLicense(token) {
  try {
    const response = await axios.get(`${process.env.API_ROOT}/api/license`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  } catch (e) {
    console.error(e)
  }
}

export async function sendMail(token, docking, vesselName) {
  try {
    const response = await axios.post(
      `${process.env.API_ROOT}/api/sendMail/mail`,
      { vesselName, docking },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data
  } catch (e) {
    console.error(e)
  }
}
