<script>
  import { navigateTo } from 'svelte-router-spa'
</script>

<div class="column is-half is-offset-one-quarter">
  <div class="notification">
    <div class="content">
      <h1 style="text-align: center;">404 - Page not found!</h1>
      <button on:click="{navigateTo('/')}">Go back</button>
    </div>
  </div>
</div>
