import axios from 'axios'

export async function getAllPostCodes(token) {
  try {
    const response = await axios.get(`${process.env.API_ROOT}/api/post-code`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  } catch (e) {
    console.error(e)
  }
}
