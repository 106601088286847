//Process each row
function processRow(row) {
  var finalVal = ''
  for (var j = 0; j < row.length; j++) {
    var innerValue = row[j] === null ? '' : row[j].toString()
    if (row[j] instanceof Date) {
      innerValue = row[j].toLocaleString()
    }
    var result = innerValue.replace(/"/g, '""')
    if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"'
    if (j > 0) finalVal += ';'
    finalVal += result
  }
  return finalVal + '\n'
}

//Create a list of lists then proccess the data and download a csv file,
function convertToCsv(rows) {
  //the csv header
  const workSheetColumnNames = ['Sknr', 'IMO', 'Nafn', 'Tegund', 'Útgerðaraðili', 'Fór seinast í slipp', 'Slipp staður', 'Upptökumannvirki', 'Boldskoðun', 'Öxulskoðun', 'Lengd']
  //The csv data
  const data = rows.map((row) => {
    return [row.id, row.imo, row.name, row.type, row.owner, row.lastDockingDate, row.location, row.dockName, row.bodyInspection, row.axleInspection, row.length] //`${row.length.toLocaleString('en-US').replace('.', ',')}`]
  })
  console.log(data)
  //Combining the header and the data
  const workSheetData = [workSheetColumnNames, ...data]
  //The file name
  const filename = 'vessels.csv'
  //Empty csv file that we will fill in with the data
  var csvFile = ''
  //Process each row of data
  for (var i = 0; i < workSheetData.length; i++) {
    csvFile += processRow(workSheetData[i])
  }
  //create a blob and url and start a download
  var blob = new Blob(['\uFEFF' + csvFile], { type: 'text/csv;charset=utf-8;' })
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename)
  } else {
    var link = document.createElement('a')
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', filename)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}

const csvVesselExport = {
  convertToCsv,
}
export default csvVesselExport
