<script>
  import { onMount } from 'svelte'
  import { navigateTo } from 'svelte-router-spa'
  import { token } from '../../stores'
  import Icon from 'svelte-awesome'
  import { refresh } from 'svelte-awesome/icons'
  import { Datatable, SearchInput } from 'svelte-simple-datatables'
  import DockingCalendar from '../components/DockingCalendar.svelte'
  import { getAllDocksProcess } from '../../services/dock'

  import { greyOut, lightBlue, lightGreen, lightRed, lightYellow, northBorder, southBorder, statusAsking, statusAskingText, statusDecided, statusDidNotCome, statusDidNotComeText, statusFinished, statusOffer, statusOfferText, statusOrder, statusOrderText } from '../../constants/colors'

  let filterDockings = 1
  let dockingsInProcess = []
  let allDockingInfo
  let loading = true
  let rows
  const settings = {
    columnFilter: true,
    pagination: false,
    // rowsPerPage: 23,
    labels: {
      search: 'Leita...',
    },
    blocks: {
      searchInput: false,
      paginationButtons: true,
      paginationRowCount: true,
    },
  }

  onMount(async () => {
    const response = await getAllDocksProcess($token)
    if (response) {
      allDockingInfo = response
      dockingsInProcess = allDockingInfo.filter((v) => v.inProcess)
      dockingsInProcess.sort((a,b) => a.startDate > b.startDate ? 1 : -1) //order by start date asc
    } else {
      console.log('ERROR getting dockins ', response)
    }
    loading = false
  })

  //When vessels in the table are clicked
  const openVesselDock = (vesselId, dockId) => {
    const route = `vessel/${vesselId}/docking/${dockId}`
    navigateTo(route)
  }

  function getStatusColor(status) {
    //TODO Change the status to its own db table that holds the colors
    if (status.toString().trim() === 'Ákveðið') {
      return `background-color:${statusDecided}; color: white;`
    } else if (status.toString().trim() === 'Tilboð') {
      return `background-color:${statusOffer}; color: ${statusOfferText};`
    } else if (status.toString().trim() === 'Pöntun') {
      return `background-color:${statusOrder}; color: ${statusOrderText};`
    } else if (status.toString().trim() === 'Lokið') {
      return `background-color:${statusFinished}; color: white;`
    } else if (status.toString().trim() === 'Fyrirspurn') {
      return `background-color:${statusAsking}; color: ${statusAskingText};`
    } else if (status.toString().trim() === 'Kom ekki') {
      return `background-color:${statusDidNotCome}; color: ${statusDidNotComeText};`
    } else {
      return 'border: black 1px solid'
    }
  }

  function filterDockingOnGoing(docking) {
    if (filterByValid(docking) && docking.status != 'Lokið') {
      return true
    }
    return false
  }

  function filterAllDockingInProccess(docking) {
    if (filterByValid(docking)) {
      return true
    }
    return false
  }

  function filterDockingThatFinished(docking) {
    if (filterByValid(docking) && docking.status == 'Lokið') {
      return true
    }
    return false
  }

  function filterDecidedDocking(docking) {
    if (filterByValid(docking) && docking.status == 'Ákveðið') {
      return true
    }
    return false
  }

  function filterByValid(docking) {
    if (docking && docking.id && docking.inProcess) {
      return true
    }
    return false
  }
</script>

<div>
  <div class="level-item has-text-centered">
    <h1 class="title is-3 has-text-centered" style="display: inline; ">Sáturlisti</h1>
  </div>
  {#if loading}
    <div class="section">
      <Icon spin data="{refresh}" scale="3" />
    </div>
  {:else}
    <div class="level" style="flex-direction: column;">
      <DockingCalendar />
    </div>
    <div style="padding-bottom: 0rem;">
      <div class="level">
        <div class="level-left">
          <SearchInput class="input" id="{'vesselTable'}" />
          <div>
            <label>
              <input type="radio" style="margin-left: 10px; transform: scale(1.2)" bind:group="{filterDockings}" value="{1}" />
              Öll verkefni
            </label>
            <label>
              <input type="radio" style="margin-left: 10px; transform: scale(1.2)" bind:group="{filterDockings}" value="{2}" />
              Öll verkefni sem eru í vinnslu
            </label>
            <label>
              <input type="radio" style="margin-left: 10px; transform: scale(1.2)" bind:group="{filterDockings}" value="{3}" />
              Verkefni sem er lokið
            </label>
            <label>
              <input type="radio" style="margin-left: 10px; transform: scale(1.2)" bind:group="{filterDockings}" value="{4}" />
              Verkefni sem hafa verið ákveðinn
            </label>
          </div>
        </div>
        <!-- <button class="button is-info " style="margin-right: 16px;" on:click="{() => navigateTo('vessel')}">Bæta við í Slipp</button> -->
      </div>

      <div style="min-height:90vh" class="table">
        <Datatable
          settings="{settings}"
          data="{filterDockings == 1
            ? dockingsInProcess.filter(filterAllDockingInProccess)
            : filterDockings == 2
            ? dockingsInProcess.filter( filterDockingOnGoing)
            : filterDockings == 3
            ? dockingsInProcess.filter(filterDockingThatFinished)
            : filterDockings == 4
            ? dockingsInProcess.filter(filterDecidedDocking)
            : dockingsInProcess.filter(filterByValid)}"
          bind:dataRows="{rows}"
          id="{'vesselTable'}"
        >
          <thead>
            <tr>
              <th data-key="dock">Upptökumannvirki</th>
              <th data-key="vesselName">Nafn</th>
              <th data-key="vesselId">Númer</th>
              <th data-key="status"> Staða verks</th>
              <th data-key="startDate">Upphaf verks</th>
              <th data-key="slippDate">Slipptaka</th>
              <th data-key="conclusionDate">Niðursetning</th>
              <th data-key="endDate">Lok verks</th>
              <th data-key="supervision">Umsjón</th>
              <th data-key="contacts">Tengiliðir útgerðar</th>
            </tr>
          </thead>
          <tbody>
            {#if rows}
              {#each $rows as row}
                <tr class="clickable" style="text-align: center; " on:click="{() => openVesselDock(row.vesselId, row.id)}">
                  <td
                    style="white-space:normal; inline-size: 250px; word-break: break-word; border: rgba(0,0,0,0.2) 1px solid;{row.dock === 'Litla dráttarbraut'
                      ? `background-color:${lightGreen};`
                      : row.dock === 'Litla dráttarbraut (Norður)'
                      ? `background-color:${lightGreen}; border: 2px dashed ${northBorder}`
                      : row.dock === 'Litla dráttarbraut (Suður)'
                      ? `background-color:${lightGreen}; border: 2px dashed ${southBorder}`
                      : row.dock === 'Aðaldráttarbraut'
                      ? `background-color:${lightBlue};`
                      : row.dock === 'Aðaldráttarbraut (Norður)'
                      ? `background-color:${lightBlue}; border: 2px dashed ${northBorder}`
                      : row.dock === 'Aðaldráttarbraut (Suður)'
                      ? `background-color:${lightBlue}; border: 2px dashed ${southBorder}`
                      : row.dock === 'Flotkví'
                      ? `background-color:${lightYellow};`
                      : row.dock === 'Bryggjukantur'
                      ? `background-color:${lightRed};`
                      : `background-color:${greyOut};`}">{row.dock ? row.dock : `---`}</td
                  >
                  <td> {row.vesselName ? row.vesselName : '---'}</td>
                  <td> {row.vesselId ? row.vesselId : '---'}</td>
                  <td> <div style="{`${getStatusColor(row.status)}; border: rgba(0,0,0,0.2) 1px solid; box-shadow: 1px 1px 1px rgba(0,0,0,0.5)`}" class="status">{row.status ? row.status : '---'}</div></td>
                  <td>{row.startDate ? row.startDate : '---'}</td>
                  <td>{row.slippDate ? row.slippDate : '---'}</td>
                  <td>{row.conclusionDate ? row.conclusionDate : '---'}</td>
                  <td>{row.endDate ? row.endDate : '---'}</td>
                  <td style="white-space:normal; inline-size: 130px; word-break: break-word;">{row.supervision ? row.supervision : '---'}</td>
                  <td nowrap style="white-space:normal; inline-size: 180px; word-break: break-word;">{row.contacts ? row.contacts : '---'}</td>
                </tr>
              {/each}
            {/if}
          </tbody>
        </Datatable>
      </div>
    </div>
  {/if}
</div>

<style>
  tr:hover {
    transform: scaleY(1.05);
    background: whitesmoke;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  }
  .status {
    border-radius: 10px;
  }
  td {
    vertical-align: middle;
  }
</style>
