import { writable } from 'svelte/store'

//get the value out of storage on load, We are storing "token" which is the auth token we use to send api requests
const stored = localStorage.token
export const token = writable(stored || '')
token.subscribe((value) => (localStorage.token = value))

//used on adminLayout to control what the user can access depending on if hes logged in or not
export const isAuthenticated = writable(false)

//Data about the user, can access his name and email fx : user.name , user.email.  This is not used in the project for now, might change
export const user = writable({})

//The auth0client, use this to sign in the user and access all auth0 things
export const auth0Client = writable({})

//If the login popup screen is on screen or not.
export const popupOpen = writable(false)

//Filters that we want to have saved for better user expirience 
export const filterDockingStore = writable(true)
export const filterFloatingDock = writable(true)
export const filterSmallDock = writable(true)
export const filterMainDock = writable(true)
export const filterDockSide = writable(true)

//not used
export const error = writable()

export const icelandic = writable(true)
