<script>
  import { onMount } from 'svelte'
  import { navigateTo } from 'svelte-router-spa'
  import Icon from 'svelte-awesome'
  import { check } from 'svelte-awesome/icons'
  import { token, user } from '../../stores'
  import { getAllDocksForVessel, createDock, getAllDocks } from '../../services/dock'
  import VesselInformation from './VesselInformation.svelte'
  // import { getVesselFromSkutan} from '../../services/pythonFetch'
  import { checkboxGreen } from '../../constants/colors'

  export let currentRoute
  let routeVesselId = currentRoute.namedParams.vesselId
  let dockingList = {}
  let loading = true
  let dockList = []
  // let skutanList = []

  onMount(async () => {
    //Get all docks available for this vessel id
    const response = await getAllDocksForVessel($token, routeVesselId)
    if (response) {
      dockingList = response
      dockingList.sort(function (a, b) {
        return new Date(b.slippDate) - new Date(a.slippDate)
      })
    } else {
      console.log('ERROR getting all Docks ')
    }

    //TESTING SKUTAN API 
    // const responseskutan = await getVesselFromSkutan()
    //  console.log('Getting Skutan')
    // if (responseskutan) {
    //   skutanList = responseskutan
    // } else {
    //   console.log('ERROR skutan get all ')
    // }

    // console.log(skutanList)

    //Get all docks
    const responseDocks = await getAllDocks($token)
    if (responseDocks) {
      loading = false
      dockList = responseDocks
    } else {
      console.log('ERROR getting all docks ')
    }
  })

  //If a specific dock is clicked
  const openDocking = (dockingId) => {
    const route = `vessel/${routeVesselId}/docking/${dockingId}`
    navigateTo(route)
  }

  //If "create docking" button is clicked
  async function createDocking() {
    await createDock($token, routeVesselId, $user.name)
  }

  function getDockName(id) {
    let theDock = dockList.find((d) => d.id === id)
    return theDock.name
  }

  function stationDays(slippDate, concDate) {
    var date1 = new Date(concDate)
    var date2 = new Date(slippDate)
    var diff = date1.getTime() - date2.getTime()
    return Math.ceil(diff / (1000 * 3600 * 24))
  }
</script>

{#if loading}<div> </div>{:else}
  <div class="docking-container">
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <div class="title is-4">Slippskráningar</div>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item"><button class="button is-success" on:click="{() => createDocking()}">Stofna slipp</button> </div>
      </div>
    </div>
    <hr />
    <div class="table-container" style="padding-bottom: 10px; ">
      <table class="table is-hoverable">
        {#if dockingList.length <= 0}
          Engar slippskráningar hafa verið stofnaðar fyrir þetta skip.
        {:else}
          <thead>
            <tr class="px-0" style="text-align:center">
              <th>Er í sáturlista</th>
              <th>Upptökumannvirki</th>
              <th>Staður slipps eða þjónustu</th>
              <th>Upphaf- verks</th>
              <th>Slipptaka</th>
              <th>Niðursetning</th>
              <th>Lok verks</th>
              <th>Stöðu-dagar</th>
              <th>Umsjón</th>
              <!-- <th>Slipptaka</th> -->
              <th>Botn og síðulokar </th>
              <th>Botnþvottur og málning</th>
              <th>Síður þvottur og málning</th>
              <th>Ofandekks þvottur og málning</th>
              <th>Tankahreinsun</th>
              <th>Öxuldráttur</th>
              <th>Öxulþétti</th>
              <th>Stýri</th>
              <th>Stýrisvél</th>
              <th>Anóður</th>
              <th>Gróðurvarnarskaut</th>
            </tr>
          </thead>

          <tbody style="border-bottom: 1px solid #dbdbdb;">
            {#each dockingList as row, index (row.id)}
              <tr class="clickable" on:click="{() => openDocking(row.id)}" style="{`text-align:center;`}">
                <td nowrap>
                  {#if row.inProcess}
                    <Icon style="{`color: ${checkboxGreen}`}" data="{check}" />
                  {/if}
                </td>
                <td nowrap> {row.dockId ? getDockName(row.dockId) : '---'}</td>
                <td nowrap> {row.location ? row.location : '---'}</td>
                <td nowrap>{row.startDate ? row.startDate : '---'}</td>
                <td nowrap>{row.slippDate ? row.slippDate : '---'}</td>
                <td nowrap>{row.conclusionDate ? row.conclusionDate : '---'}</td>
                <td nowrap>{row.endDate ? row.endDate : '---'}</td>
                <td nowrap>{row.slippDate && row.conclusionDate ? stationDays(row.slippDate, row.conclusionDate) : '---'}</td>
                <td nowrap style="white-space:normal;">{row.supervision ? row.supervision : '---'}</td>
                <!-- <td>
                {#if row.docking}
                  <Icon style="{`color: ${checkboxGreen}`}" data="{check}" />
                {/if}
              </td> -->
                <td>
                  {#if row.seaValves}
                    <Icon style="{`color: ${checkboxGreen}`}" data="{check}" />
                  {/if}
                </td>
                <td>
                  {#if row.bottomWash}
                    <Icon style="{`color: ${checkboxGreen}`}" data="{check}" />
                  {/if}
                </td>
                <td>
                  {#if row.deepWash}
                    <Icon style="{`color: ${checkboxGreen}`}" data="{check}" />
                  {/if}
                </td>
                <td>
                  {#if row.topDeckWasj}
                    <Icon style="{`color: ${checkboxGreen}`}" data="{check}" />
                  {/if}
                </td>
                <td>
                  {#if row.tankCleaning}
                    <Icon style="{`color: ${checkboxGreen}`}" data="{check}" />
                  {/if}
                </td>
                <td>
                  {#if row.propellerShaftOverhaul}
                    <Icon style="{`color: ${checkboxGreen}`}" data="{check}" />
                  {/if}
                </td>
                <td>
                  {#if row.shaftSeal}
                    <Icon style="{`color: ${checkboxGreen}`}" data="{check}" />
                  {/if}
                </td>
                <td
                  >{#if row.rudderShaft}
                    <Icon style="{`color: ${checkboxGreen}`}" data="{check}" />
                  {/if}
                </td>
                <td>
                  {#if row.rudderMachine}
                    <Icon style="{`color: ${checkboxGreen}`}" data="{check}" />
                  {/if}
                </td>
                <td>
                  {#if row.zinkAnodes}
                    <Icon style="{`color: ${checkboxGreen}`}" data="{check}" />
                  {/if}
                </td>
                <td>
                  {#if row.cathelco}
                    <Icon style="{`color: ${checkboxGreen}`}" data="{check}" />
                  {/if}
                </td>
                <td>
                  {#if row.details}
                    <Icon style="{`color: ${checkboxGreen}`}" data="{check}" />
                  {/if}
                </td>
              </tr>
            {/each}
          </tbody>
        {/if}
      </table>
    </div>
  </div>
  <br />
  <br />
  <VesselInformation currentRoute="{currentRoute}" />
{/if}

<style>
  .px-0 th {
    vertical-align: bottom;
  }
  tbody tr:hover {
    background-color: lightcyan;
  }
  .docking-container {
    border-radius: 10px;
    border: #dbdbdb solid 1px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    margin: 20px;
    padding: 20px;
    margin-bottom: 5rem;
    /* margin-top: 5rem; */
  }
</style>
