<script>
  import { onMount } from 'svelte'
  import auth from './authService'
  import { isAuthenticated, user, auth0Client } from './stores'
  import { Router } from 'svelte-router-spa'
  import { routes } from './routes'

  onMount(async () => {
    //Sets the auth0 client that is signed in
    auth0Client.set(await auth.createClient())
    //Checks if user is signed in
    isAuthenticated.set(await $auth0Client.isAuthenticated())
    //User info, gets email, name and more personal info from their account
    user.set(await $auth0Client.getUser())
  })
</script>

<main>
  <Router routes="{routes}" />
</main>
