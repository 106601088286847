import createAuth0Client from '@auth0/auth0-spa-js'
import { user, isAuthenticated, popupOpen, token } from './stores'
import config from './authConfig.js'
import { navigateTo } from 'svelte-router-spa'

//Creates a auth0 client
async function createClient() {
  let auth0Client = await createAuth0Client({
    domain: config.domain,
    client_id: config.clientId,
    audience: config.audience,
    useRefreshTokens: true,
    cacheLocation: 'localstorage',
    prompt: 'select_account',
  })

  return auth0Client
}

//When user clicks "login" then auth0 popup window pops up
async function loginWithPopup(client, options) {
  popupOpen.set(true)
  try {
    await client.loginWithPopup(options)
    user.set(await client.getUser())
    isAuthenticated.set(true)
    token.set(await client.getTokenSilently())
    navigateTo('/vessel')
  } catch (e) {
    console.error(e)
  } finally {
    popupOpen.set(false)
  }
}

//on logout when user clicks on "logout" in the header
function logout(client) {
  token.set('')
  return client.logout()
}

const auth = {
  createClient,
  loginWithPopup,
  logout,
}

export default auth
