//Vessel docking colors
export const lightGreen = '#a8ffb2'
export const lightBlue = '#a8f1ff'
export const lightYellow = '#fff3a8'
export const lightRed = '#ffa8a8'
export const greyOut = '#999999'
export const northBorder = '#3467d5'
export const southBorder = '#d53434'

//Status colors
export const statusDecided = '#4A8206'
export const statusAsking = '#c3caf9'
export const statusOrder = '#CAF0CC'
export const statusFinished = '#757575'
export const statusOffer = '#ffebc0'
export const statusDidNotCome = '#fabbc3'

export const statusAskingText = '#3E5EEB'
export const statusOrderText = '#447407'
export const statusOfferText = '#be7801'
export const statusDidNotComeText = '#a4262c'

//Checkbox color
export const checkboxGreen = '#4BB543'
